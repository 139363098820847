<template>
<!-- 稽山街道体育产业智慧平台—越城区 -->
    <div>
        <div id="shaoxing">
            <div class="home-left">
                <jishanleft></jishanleft>
            </div>
            <div class="home-top">
                <jishantop></jishantop>
            </div>
            <div class="home-right">
                <jishanright></jishanright>
            </div>
        </div>
    </div>
    
</template>

<script>
import {show} from '../../assets/js/animate'
export default {
    components:{
        jishanleft: () => import('../charts/jishanone/jishanleft.vue'),
        jishantop: () => import('../charts/jishanone/jishantop.vue'),
        jishanright: () => import('../charts/jishanone/jishanright.vue'),
    },
    data () {
        return {
            
        }
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
			$('#amap').show();
        },
    },
}
</script>

<style scoped>

</style>